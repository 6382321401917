import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const fadeAnimations = () => {
  gsap.config({
    nullTargetWarn: false,
  });
  
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".js-fadeIn").forEach((element) => {
    gsap.to(element, {
      opacity: 1,
      duration: 1.2,
      scrollTrigger: {
        trigger: element,
        start: "top 90%",
        end: "bottom 10%",
        toggleActions: "play none none none",
      },
    });
  });

  gsap.utils.toArray(".js-fadeUp").forEach((element) => {
    const dataDelay = element.dataset.delay;
    const delay = window.innerWidth <= 767 ? 0 : (dataDelay ? parseFloat(dataDelay) / 1000 : 0);

    gsap.to(element, {
      y: 0,
      opacity: 1,
      duration: 1.2,
      delay: delay,
      scrollTrigger: {
        trigger: element,
        start: "top 90%",
        end: "bottom 10%",
        toggleActions: "play none none none",
      },
    });
  });
};