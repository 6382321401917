//import内容を自動的にバンドル

import '../scss/style.scss';

import { hambugerMenu } from './module/hambugerMenu.js';
hambugerMenu();

import { toTop } from './module/toTop.js';
toTop();

import { fadeAnimations } from './module/fadeAnimations.js';
fadeAnimations();