export const hambugerMenu = () => {
  const body = document.querySelector('body');
  const hambButton = document.querySelector('.js-hambButton');

  hambButton.addEventListener('click', () => {
    const dataHamb = body.getAttribute('data-hamb');
    if(dataHamb === 'open') {
      body.setAttribute('data-hamb', 'close');
    }
    if(dataHamb === 'close') {
      body.setAttribute('data-hamb', 'open');
    }
  });

  window.addEventListener('resize', () => {
    const windowWidth = window.innerWidth;
    if(windowWidth >= 768) {
      body.setAttribute('data-hamb', 'close');
    }
  });
};